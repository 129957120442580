<template>
  <b-modal @change="change" :visible="visible" hide-footer hide-header centered>
    <div class="warning p-2 pt-3">
      <img src="../assets/images/warning-icon.svg" class="mb-4" />

      <h3 class="text-center px-4 pt-2 mb-4" v-if="title.length">
        {{ title }}
      </h3>

      <div class="text-center text-muted px-4 pt-2 pb-3 mb-4 text">
        {{ text }}
      </div>

      <div class="row">
        <div class="mx-auto">
          <button
            class="btn btn-success btn-lg font-weight-bold"
            @click="confirm"
          >
            {{ confirmButtonText }}
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      defaul: false
    },
    text: {
      type: String,
      default: "Tem certeza?"
    },
    title: {
      type: String,
      default: ""
    },
    confirmButtonText: {
      type: String,
      default: "Confirmar"
    }
  },
  model: {
    prop: "visible",
    event: "change"
  },
  methods: {
    change(val) {
      this.$emit("change", val);
    },
    confirm() {
      this.$emit("confirm");
      this.change(false);
    }
  }
};
</script>

<style lang="scss">
.warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  .text {
    font-size: 14px;
    line-height: 17px;
  }
}
</style>
