export default {
  getDistricts(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
        .then(response => {
          let results = response.data.sort(function(a, b) {
            if (a.nome < b.nome) return -1;
            if (a.nome > b.nome) return 1;
            return 0;
          });
          results.forEach(el => {
            el.name = el.nome;
            delete el.nome;
          });
          context.commit("setDistricts", results);
          resolve(response.data);
        })
        .catch(reject);
    });
  },

  getCities(context, uf) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`
        )
        .then(response => {
          let results = response.data;
          results.forEach(el => {
            el.name = el.nome;
            delete el.nome;
          });
          context.commit("setCities", results);
          resolve(response.data);
        })
        .catch(reject);
    });
  }
};
