<template>
  <span class="loading">
    <span v-if="!show">
      <slot />
    </span>
    <span v-else>&nbsp;</span>

    <i v-if="show" :class="`icon-spinner text-${variant}`"></i>
  </span>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    variant: {
      type: [Array, String],
      default: "dark"
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes example {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  .icon-spinner:before {
    animation: example 1s infinite linear;
    display: inline-block;
  }
}
</style>
