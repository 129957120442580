<template>
  <div class="components-custom-header position-fixed w-100">
    <div class="container-fluid">
      <div class="d-lg-none">
        <button
          @click="$emit('show-menu', true)"
          class="btn btn-sm-sq text-light"
        >
          <i class="icon-menu"></i>
        </button>
      </div>
      <router-link
        @click="active_tab = 0"
        :to="{ name: 'home' }"
        class="link-logo link-control text-center text-lg-left d-none d-lg-inline pl-0"
      >
        <img
          width="115"
          height="61.8"
          src="@/assets/images/logo.png"
          alt="Logo Elite Digital"
        />
      </router-link>
      <!-- <router-link
        v-else
        :to="{ name: 'login' }"
        class="link-logo link-control text-center text-lg-left d-none d-lg-inline pl-0"
        @click.native="$emit('show-menu', false)"
      >
        <img
          width="115"
          height="61.8"
          src="@/assets/images/logo.png"
          alt="Logo Elite Digital"
        />
      </router-link> -->
      <router-link
        @click="active_tab = 0"
        :to="{ name: 'home' }"
        class="link-logo link-control text-center text-lg-left flex-fill d-lg-none"
      >
        <img
          width="115"
          height="61.8"
          src="@/assets/images/logo.png"
          alt="Logo Elite Digital"
        />
      </router-link>

      <button
        @click="goHome"
        class=" btn d-none d-lg-block link-control"
        :class="active_tab == 'home' ? 'active' : ''"
      >
        Visão geral
      </button>
      <button
        @click="goProfessionals"
        class="btn d-none d-lg-block link-control"
        :class="active_tab == 'professionals' ? 'active' : ''"
      >
        Ver profissionais
      </button>
      <button
        @click="goProviders"
        class=" btn d-none d-lg-block link-control"
        :class="active_tab == 'providers' ? 'active' : ''"
      >
        Ver fornecedores
      </button>
      <button
        @click="checkOpportunity"
        class="d-none btn d-lg-block link-control"
        :class="active_tab == 'opportunities' ? 'active' : ''"
      >
        Oportunidades
      </button>
      <button
        @click="checkProjects"
        class="d-none btn d-lg-block link-control"
        :class="active_tab == 'new-opportunity' ? 'active' : ''"
      >
        Minhas oportunidades
      </button>
      <div class="flex-lg-fill d-flex align-items-center justify-content-end">
        <actions-dropdown
          v-if="isLogged"
          :actions="profileActions"
          @jobs="goToProfessionalProfile"
          @account="goTo('my-account')"
          @logout="goTo('logout')"
        >
          <img
            class="profile-img mr-lg-2"
            :src="$user.profile.perfil_thumbnail || $user.placeholderUrl"
            alt=""
          />
          <b-spinner
            v-if="loadingName"
            variant="light"
            style="width: 1rem; height: 1rem;"
          />
          <span
            v-if="isLogged && !loadingName"
            class="d-none d-lg-inline text-name"
            >{{ profile?.name || $user.user.name }}</span
          >
        </actions-dropdown>
        <div v-else>
          <img
            @click="showLogin = !showLogin"
            class="profile-img mr-lg-2"
            :src="$user.placeholderUrl"
            alt="placeholder"
          />
        </div>
      </div>
    </div>
    <responsive-modal v-model="showLogin" :hideHeader="false" centered>
      <certification-modal
        title="TER ACESSO A TODOS OS BENEFÍCIOS"
        text1="Faça login ou cadastre-se no Bora Conecta para"
        text2="da plataforma."
        buttonCopy="Entrar"
        buttonLink="login"
        type="login"
      />
    </responsive-modal>
    <responsive-modal v-model="showCertification" :hideHeader="false" centered>
      <certification-modal
        title="Formação em Projetos e Obras BORAnaOBRA"
        text1="Somente certificados na"
        text2="podem ver oportunidades."
        buttonCopy="Quero ser certificado"
        buttonLink="https://google.com"
        type="certificate"
      />
    </responsive-modal>
  </div>
</template>
<script>
import CertificationModal from "@/components/home/CertificationModal.vue";
export default {
  components: { CertificationModal },
  data() {
    const active_tab = this.$route.name;
    return {
      showLogin: false,
      showCertification: false,
      active_tab,
      profile: null,
      loadingName: false,
      scroll: window.document.onscroll
    };
  },
  computed: {
    isLogged() {
      return this.$store.state.user.logged;
    },
    profileActions() {
      const profileOption = {
        icon: "icon-jobs",
        text: "Perfil profissional",
        class: "text-light",
        confirmation: {
          enable: false,
          text: ""
        },
        event: "jobs"
      };
      const actionList = [
        {
          icon: "icon-eva_person-outline",
          text: "Minha conta",
          class: "text-light",
          confirmation: {
            enable: false,
            text: ""
          },
          event: "account"
        },
        {
          icon: "icon-gridicons_sign-out",
          text: "Sair",
          class: "text-light",
          confirmation: {
            enable: false,
            text: ""
          },
          event: "logout"
        }
      ];

      // if (this.$user.profile?.emblem?.is_active) {
      return [profileOption, ...actionList];
      //}

      // return actionList;
    }
  },
  watch: {
    "$route.name"(name) {
      this.active_tab = name;
    }
  },
  methods: {
    goTo(name) {
      if (name == "logout") {
        this.$store.dispatch("logout").then(() => {
          this.$router.push({ name: "login" });
        });
      }

      this.$router.push({ name });
    },
    goToProfessionalProfile() {
      if (this.$user.profile?.emblem?.is_active) {
        if (this.$user.profile?.id) {
          this.$router.push({
            name: "professional-profile",
            params: { slug: this.$user.profile.slug }
          });
        } else {
          this.$router.push({ name: "my-professional-profile" });
        }
      } else {
        this.showCertification = true;
      }
    },
    checkProjects() {
      if (this.$user.user) {
        this.doEmit();
        this.$router.push({ name: "new-opportunity" });
      } else {
        this.showLogin = true;
      }
    },
    checkOpportunity() {
      if (this.$user.user) {
        if (this.$user.profile.emblem) {
          this.doEmit();
          this.$router.push({ name: "opportunities" });
        } else {
          this.showCertification = true;
        }
      } else {
        this.showLogin = true;
      }
    },
    goHome() {
      this.doEmit();
      this.$router.push({ name: "home" });
    },
    goProviders() {
      this.doEmit();
      this.$router.push({ name: "providers" });
    },
    doEmit() {
      this.$emit("show-menu", false);
    },
    goProfessionals() {
      this.doEmit();
      this.$router.push({ name: "professionals" });
    }
  },
  mounted() {
    this.loadingName = true;
    this.$store
      .dispatch("user/getThirdProfile", this.$user.profile.slug)
      .then(data => {
        this.profile = data;
      })
      .finally(() => {
        this.loadingName = false;
      });
  }
};
</script>

<style lang="scss" scoped>
.components-custom-header {
  width: 100%;
  background: #000;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
  z-index: 10;
  top: 0;
  .container-fluid {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .profile-img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    cursor: pointer;
  }

  .text-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #fbfbfb;
    @media screen and (max-width: 320px) {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .link-control {
    &:hover {
      text-decoration: underline;
    }
    @media screen and (max-width: 768px) {
      flex: 1 1 auto;
    }
  }

  .active {
    background: rgba(1, 65, 108, 0.05);
    border-bottom: 3px solid #ffcc00;
    color: #ffcc00 !important;
    border-radius: 0px;
  }
}
</style>
