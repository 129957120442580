import { removeToken } from "../setup/api";
import { getUserInfo, removeUserInfo } from "../setup/auth";

export default {
  postLogin(context) {
    return new Promise((resolve, reject) => {
      context.commit("setLoading", true);
      Promise.all([context.dispatch("user/get")])
        .then(() => {
          context.commit("user/setUser", getUserInfo());
          context.commit("user/setLogged", true);
          resolve();
        })
        .catch(reject)
        .finally(() => {
          context.commit("setLoading", false);
        });
    });
  },

  logout(context) {
    return new Promise(resolve => {
      removeToken();
      removeUserInfo();
      context.commit("user/reset");
      resolve();
    });
  }
};
