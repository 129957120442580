export default {
  setProviders(state, providers) {
    state.providers = providers;
  },
  setProvider(state, provider) {
    state.provider = provider;
  },
  setCategories(state, categories) {
    state.categories = categories;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  }
};
