<template>
  <div class="components-checkbox-group">
    <div @click="toggle" class="checkbox d-flex align-items-center">
      <i
        :class="{
          'icon-square text-secondary': innerSelected == false,
          'icon-checked-square text-dark bg-primary': innerSelected == true
        }"
      ></i>
      <span class="small font-weight-bold mt-n1">{{ text }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerSelected: this.value
    };
  },
  watch: {
    innerSelected(v) {
      this.$emit("input", v);
    },
    value(v) {
      this.innerSelected = v;
    }
  },
  methods: {
    toggle() {
      this.innerSelected = !this.innerSelected;
    }
  }
};
</script>
<style lang="scss" scoped>
.components-checkbox-group {
  padding-left: 5px;
  .checkbox {
    cursor: pointer;
    padding-top: 8px;
    i {
      font-size: 16px;
      margin-right: 8px;
      border-radius: 50%;
    }

    span {
      color: #595463;
    }
  }

  .blocked {
    opacity: 0.5;
  }
  .choose-one {
    pointer-events: none;
  }
}
</style>
