<template>
  <div :class="'card ' + shape">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    shape: {
      type: String,
      default: "small"
    }
  }
};
</script>
<style lang="scss" scoped>
.card {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px !important;
  background: #f5f5f5;
  position: relative;
  border: 0;
  overflow: hidden;

  .btn-options {
    position: absolute;
    top: 5%;
    right: 5%;
  }

  &.large {
    height: 480px !important;
    @media screen and (max-width: 768px) {
      width: 100% !important;
      height: auto !important;
    }
  }

  &.small {
    @media screen and (max-width: 768px) {
      width: 100% !important;
      height: auto !important;
    }

    ::v-deep .btn-sm-sq {
      height: 32px !important;
      width: 32px !important;
    }
  }

  &.profile {
    height: 64px;
    width: 259px;

    ::v-deep .btn-sm-sq {
      height: 32px !important;
      width: 32px !important;
    }
  }

  .card-img {
    border-radius: 16px 16px 0px 0px;

    &.sm {
      width: 259px;
      height: 176.98px;
    }
  }

  .card-header {
    &.red {
      background: linear-gradient(180deg, #bf4588 0%, #ff3b47 88.79%);
    }
    &.purple {
      background: linear-gradient(180deg, #914bf2 0%, #8999f3 100%);
    }
    &.yellow {
      background: linear-gradient(180deg, #ffcc00 0%, #f2a30f 100%);
    }

    border-bottom: 0;
    padding: 16px;
    min-height: 313px;
    max-height: 313px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    border-radius: 16px 16px 0px 0px;

    &.black {
      background-color: #1b1d20;
    }
  }

  .card-body {
    padding: 16px;

    .professional-img {
      height: 32px;
      width: 32px;
      border-radius: 50%;
    }

    .professional-name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #595959;
    }

    .professional-rating {
      & > * {
        color: #ffcc00;
      }
      i {
        font-size: 24px;
      }
      span {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.015em;
      }
    }

    .occupation-wrapper {
      padding-left: 0.1rem;
      i {
        font-size: 30px;
        margin-right: 0.6rem;
        color: #2e3c8c;
      }

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.015em;
        color: #737373;
      }
    }
  }

  .card-footer {
    border-radius: 0px 0px 16px 16px;
    padding: 16px;
    background: #f5f5f5;
    border-top: 0;
    p {
      font-size: 14px;
      text-align: center;
    }
  }
}
</style>
