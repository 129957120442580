import { normalizeText, clearObject, prepareParams } from "@/functions/helpers";
import constants from "@/constants.js";
const RESULTS_PER_PAGE = constants.JSON.RESULTS_PER_PAGE;

export default {
  get({ dispatch }) {
    dispatch("search");
  },

  getHome(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/perfils/?order_by=random&limit=8")
        .then(data => {
          context.commit("setProfessionals", data.data.results);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  search(context, params) {
    return new Promise((resolve, reject) => {
      //  let payload = {};

      //  if (params.q) {
      //    payload = {
      //      query: `${params.q}${
      //        params.category_id__in ? `,${params.category_id__in}` : ``
      //      }`
      //    };
      //  } else {
      //     const cleaned = { ...params };
      //     delete cleaned.page;
      //     delete cleaned.order_by;

      //    payload = {
      //      query: Object.entries(params)
      //        .map(([_, v]) => v)
      //        .join(","),
      //      limit: constants.JSON.RESULTS_PER_PAGE,
      //      offset: constants.JSON.RESULTS_PER_PAGE * ((params.page ?? 1) - 1)
      //    };
      //  }
      const queryParams = new URLSearchParams(prepareParams(params));
      window.axios
        .get(`/perfils/search?${queryParams}`)
        .then(({ data: { count, next, previous, total, results } }) => {
          let resultsApi = results;

          context.commit("setPagination", {
            count,
            next,
            previous,
            total,
            per_page: RESULTS_PER_PAGE
          });

          resultsApi = resultsApi.map(result => {
            result.perfil_thumbnail =
              result.perfil_thumbnail ??
              require("@/assets/images/user-placeholder.png");

            return result;
          });

          context.commit("setProfessionals", resultsApi);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getCategories(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/perfils/categories?limit=99999")
        .then(response => {
          context.commit("setCategories", response.data.results);
          resolve(response.data.results);
        })
        .catch(reject);
    });
  },

  getTags(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/perfils/tags?limit=999999")
        .then(response => {
          context.commit("setTags", response.data.results);
          resolve(response.data.results);
        })
        .catch(reject);
    });
  },
  getTagsByCategories(context, ids) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/perfils/tags?category_id__in=" + ids)
        .then(response => {
          context.commit("setTags", response.data.results);
          resolve(response.data.results);
        })
        .catch(reject);
    });
  }
};
