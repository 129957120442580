export default {
  getPortfolio(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/perfils/portfolio")
        .then(response => {
          context.commit("setPortfolio", response.data.results[0]);
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  createPortfolio(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/perfils/portfolio/create", data)
        .then(response => {
          resolve(response);
        })
        .catch(reject);
    });
  },
  updatePortfolio(context, { id, data }) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/perfils/portfolio/update/${id}`, data)
        .then(response => {
          resolve(response);
        })
        .catch(reject);
    });
  },
  deletePortfolio(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .delete(`/perfils/portfolio/update/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(reject);
    });
  },
  getThirdPortfolio(context, user_id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/perfils/portfolio/${user_id}/perfil`)
        .then(response => {
          context.commit("setPortfolio", response.data.results[0]);
          resolve(response);
        })
        .catch(reject);
    });
  }
};
