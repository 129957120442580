import Vue from "vue";
import Router from "vue-router";
// import VueGtm from "vue-gtm";

Vue.use(Router);

const vueRouter = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        protected: false
      }
    },
    {
      path: "/minha/conta",
      name: "my-account",
      component: () => import("./views/accounts/My.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        protected: true
      }
    },
    {
      path: "/profissionais",
      name: "professionals",
      component: () => import("./views/professionals/Index.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        protected: false
      }
    },
    {
      path: "/profissional/:slug",
      name: "professional-profile",
      component: () => import("./views/professionals/Profile.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        protected: false
      }
    },
    {
      path: "/meu/perfil",
      name: "my-professional-profile",
      component: () => import("./views/professionals/MyProfile.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        protected: true
      }
    },
    {
      path: "/oportunidades",
      name: "opportunities",
      component: () => import("./views/opportunities/Index.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        protected: true
      }
    },
    {
      path: "/novo/projeto",
      name: "new-opportunity",
      component: () => import("./views/opportunities/Publish.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        protected: true
      }
    },
    {
      path: "/editar/projeto/:id",
      name: "edit-opportunity",
      component: () => import("./views/opportunities/Edit.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        protected: true
      }
    },
    {
      path: "/minhas/oportunidades",
      name: "my-opportunities",
      component: () => import("./views/opportunities/MyOpportunities.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        protected: true
      }
    },
    {
      path: "/alterar-senha",
      name: "change-password",
      component: () => import("./views/ChangePassword.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        protected: false
      }
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("./views/ResetPassword.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/cadastro",
      name: "signup",
      component: () => import("./views/Register.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Login.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/esqueci-senha",
      name: "forgot-password",
      component: () => import("./views/PasswordRecovery.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/password/novo",
      name: "reset-password",
      component: () => import("./views/ResetPassword.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/termos-de-uso",
      name: "terms",
      component: () => import("./views/Terms.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    },
    {
      path: "/fornecedores",
      name: "providers",
      component: () => import("./views/Providers.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        protected: false
      }
    },
    {
      path: "/fornecedor/:id",
      name: "provider",
      component: () => import("./views/Provider.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        protected: false
      }
    },
    {
      path: "*",
      name: "not-found",
      component: () => import("./views/NotFound.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    }
  ]
});

// Vue.use(VueGtm, {
//   id: "GTM-TB4VDDF",
//   vueRouter
// });

export default vueRouter;
