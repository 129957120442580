import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

import professional from "./professional";
import opportunity from "./opportunity";
import user from "./user";
import districts from "./districts";
import banners from "./banners";
import providers from "./providers";
import services from "./services";
import portfolio from "./portfolio";

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  state,
  mutations,
  modules: {
    user,
    professional,
    opportunity,
    districts,
    banners,
    providers,
    services,
    portfolio
  }
});
