export default {
  setProfessionals(state, professionals) {
    state.professionals = professionals;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setCategories(state, categories) {
    state.categories = categories;
  },
  setTags(state, tags) {
    state.tags = tags;
  }
};
