<template>
  <b-modal
    id="responsive-modal"
    v-model="show"
    :size="size"
    :hide-header="hideHeader"
    hide-footer
    centered
  >
    <div
      class="draggable-line d-flex justify-content-center d-lg-none mb-2"
      @click="close"
      role="button"
    />
    <div class="fit-scroll-content overflow-y-scroll">
      <slot />
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      show: this.value
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "md"
    },
    hideHeader: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value(val) {
      this.show = val;
    },
    show(val) {
      this.$emit("input", val);
    }
  },
  methods: {
    close() {
      this.show = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.fit-scroll-content {
  max-height: 510px;
  overflow-x: hidden;

  &.overflow-y-scroll {
    overflow-y: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
