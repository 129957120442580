<template>
  <div class="search-filter-view d-flex align-items-center">
    <div class="flex-fill">
      <div :class="`input-view ${size}`">
        <i class="icon-search" />
        <input v-model="text" :placeholder="placeholder" />
        <!-- <div
          v-if="showResultsDropdown"
          :class="`dropdown-suggestions p-3 ${dropdownVisible}`"
        >
          <div class="d-flex justify-content-end">
            <button class="btn btn-sm close-btn" @click="close">
              <i class="icon-x" />
            </button>
          </div>
          <slot />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
let timeout;

export default {
  props: {
    placeholder: {
      type: String,
      default: "Pesquisar por cliente"
    },
    size: {
      type: String,
      default: "lg"
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      text: this.value,
      loading: true,
      dialog: false
    };
  },
  model: {
    prop: "value",
    type: "change"
  },
  watch: {
    text(newVal) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.$emit("input", newVal);
      }, 300);
    }
  }
};
</script>

<style lang="scss">
.search-filter-view {
  .input-view {
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    padding-left: 12px;
    width: 100%;
    position: relative;

    &.lg {
      max-height: 50px;
      min-height: 50px;
    }

    &.sm {
      max-height: 34px;
      min-height: 34px;
    }

    i {
      color: #737373;
    }
  }

  .dropdown-suggestions {
    position: absolute;
    min-width: 450px;
    background: #fff;
    top: 120%;
    left: 0;
    z-index: 2;
    border: 1px solid #d2d0d8;
    border-radius: 4px;
  }

  span {
    color: #aeb6c4;
  }

  i {
    font-size: 24px;
  }

  .close-btn {
    min-height: 20px;
    max-height: 20px;
    i {
      font-size: 16px;
    }
  }

  input {
    display: flex;
    flex: 1;
    border: none;
    width: 100%;
    background: transparent;
    outline: none;
  }

  button {
    @media screen and (max-width: 991px) {
      display: block;
      padding: 10px;
      max-height: 46px;
    }
  }
}

.btn-close {
  position: absolute;
  top: -8px;
  right: 0;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.form-control {
  color: #737373;
}
</style>
