<template>
  <button
    id="hollow-button"
    class="btn btn-block hollow-button"
    :class="btnClass"
    :type="type"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <loading :show="loading">{{ title }}</loading>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "submit"
    },
    title: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    styleType: {
      type: String,
      default: "success" // success or cancel
    }
  },
  computed: {
    btnClass() {
      const result = {
        "btn-outline-success": !this.disabled && this.styleType !== "cancel",
        "btn-outline-secondary": this.disabled || this.styleType === "cancel"
      };
      result[this.customClass] = true;
      return result;
    }
  }
};
</script>

<style lang="scss" scoped>
.hollow-button {
  &:disabled {
    opacity: 0.5;
  }
}
</style>
