export default {
  getHome(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/banners/")
        .then(data => {
          context.commit("setBannerHome", data.data.results);
          resolve(data.results);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getProviders(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/banners/providers")
        .then(response => {
          context.commit("setBannerProviders", response.data.results);
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
