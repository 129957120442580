import Store from "../store";

const USER_KEY = "@_BORA_CONECTA_USER_@";

const getUserInfo = () => {
  const data = localStorage.getItem(USER_KEY);

  if (!data) return undefined;

  return JSON.parse(data);
};

const saveUserInfo = user => {
  localStorage.setItem(
    USER_KEY,
    JSON.stringify({
      ...(getUserInfo() || {}),
      ...user
    })
  );
};

const removeUserInfo = () => localStorage.removeItem(USER_KEY);

const initUserInfo = () => {
  const user = getUserInfo();
  if (user) {
    Store.commit("user/setUser", user);
  }
};

export { saveUserInfo, getUserInfo, removeUserInfo, initUserInfo };
