<template>
  <div class="component-file-input form-group position-relative">
    <button
      type="button"
      class="btn btn-block text-left"
      @click="openSelectBox"
    >
      <label :for="id">
        {{ label }}
        <span v-if="lightLabel.length" class="font-weight-light">
          {{ lightLabel }}
        </span>
      </label>
      <div class="file-indicator-wrapper">
        <div
          v-if="innerValue && innerValue.length && !change"
          class="d-none d-lg-flex align-items-center"
        >
          <i class="icon-file"></i>
          <span
            v-for="(f, i) in innerValue"
            :key="`file-${i}`"
            class="placeholder mr-2"
          >
            <span class="file-name d-flex align-items-center px-2 py-1">
              <span>
                {{
                  `${f.file.name.slice(0, 20)} ${
                    f.file.name.length > 20 ? "..." : ""
                  }`
                }}
              </span>
              <button class="btn text-light" @click.stop="remove(i)">
                <i class="icon-close"></i>
              </button>
            </span>
          </span>
        </div>

        <div
          v-if="innerValue && innerValue.length && !change"
          class="d-lg-none d-flex flex-column align-items-center justify-content-center"
        >
          <i class="icon-file"></i>
          <span
            v-for="(f, i) in innerValue"
            :key="`file-${i}`"
            class="placeholder mb-2"
          >
            <span class="file-name d-flex align-items-center px-2 py-1">
              <span>
                {{
                  `${f.file.name.slice(0, 20)} ${
                    f.file.name.length > 20 ? "..." : ""
                  }`
                }}
              </span>
              <button class="btn text-light" @click.stop="remove(i)">
                <i class="icon-close"></i>
              </button>
            </span>
          </span>
        </div>
        <span v-else class="placeholder">
          <i class="icon-file"></i>
          Anexar arquivo
        </span>
      </div>
      <input
        :id="id"
        type="file"
        :accept="acceptedFileType"
        @change="inputFileChanged"
        :multiple="multiple"
      />
    </button>
  </div>
</template>

<script>
import { toBase64 } from "@/functions/helpers";

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    noPreview: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ""
    },
    lightLabel: {
      type: String,
      default: ""
    },
    file_count_limit: {
      type: Number,
      default: 5
    },
    file_size_limit: {
      type: Number,
      default: 25 // Size in MB
    },
    acceptedFileType: {
      type: String,
      default: "image/*, .pdf"
    },
    value: {
      type: [File, String, Array],
      default: null
    }
  },
  data() {
    return {
      innerValue: this.value,
      change: true
    };
  },
  model: {
    prop: "value",
    type: "change"
  },
  watch: {
    innerValue(newVal) {
      this.change = false;
      if (newVal != this.value) {
        this.$emit("input", newVal);
        this.change = true;
      }
    },
    value(newVal) {
      if (this.innerValue !== newVal) {
        this.change = false;
        this.innerValue = newVal;
      }
    }
  },

  methods: {
    remove(index) {
      this.innerValue = this.innerValue.filter((_, i) => i !== index);
    },
    openSelectBox() {
      document.getElementById(this.id).click();
    },
    async inputFileChanged(event) {
      if (event.target.files && event.target.files.length) {
        if (event.target.files.length > this.file_count_limit) {
          this.$message.error(
            "Escolha no máximo 5 arquivos com tamanho total máximo de 25MB"
          );
          return;
        }
        const arrFiles = Array.from(event.target.files);
        const files = arrFiles.map((file, index) => {
          const src = window.URL.createObjectURL(file);
          return { file, src };
        });

        try {
          if (this.multiple) {
            let file_list = [];
            for (let i in files) {
              const base64 = await toBase64(files[i].file);
              file_list.push({
                ...files[i],
                base64
              });
            }
            this.$emit("input", file_list);
            this.change = true;
            return;
          }
          const base64 = await toBase64(files[0].file);
          this.$emit("input", [
            {
              ...files[0],
              base64
            }
          ]);
          this.change = true;
          return;
        } catch (error) {
          this.$message.error(error);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.component-file-input {
  width: 100%;

  .btn {
    padding: 0;
  }

  .placeholder {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #202124;
    display: flex;
    align-items: center;
    .btn {
      font-size: 16px;
      min-height: 5px;
      min-width: 10px;
      line-height: 5px;
      margin: 0;
      padding: 0;
      i {
        font-size: 18px;
        color: #fff;
      }
    }
  }

  i {
    font-size: 26px;
    color: #202124;
  }
  .image {
    max-height: 80px;
    max-width: 80px;
    min-height: 80px;
    min-width: 80px;
    border-radius: 80px;
    object-fit: cover;
    box-shadow: 0 0 16px rgba(100, 100, 100, 0.1);
  }

  input {
    position: absolute;
    visibility: hidden;
  }

  label {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #595959;
  }

  .file-indicator-wrapper {
    background: rgba(32, 33, 36, 0.05);
    border: 1px solid rgba(32, 33, 36, 0.5);
    border-radius: 4px;
    min-height: 48px;
    height: 48px;
    width: 100%;
    @media screen and (max-width: 768px) {
      height: fit-content;
    }
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    .file-name {
      background: #8c8c8c;
      border-radius: 16px;
      max-width: 228px;
      font-size: 12px;
      color: #fff;

      span {
        max-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
