<template>
  <div class="components-select-dropdown d-flex flex-column">
    <label v-if="label" for="dropdown" :class="`text-${labelColor}`">
      {{ label }}
    </label>
    <b-dropdown
      id="dropdown"
      ref="dropdown"
      variant="light"
      :text="selected_value"
      dropdown
    >
      <div v-if="options.length" class="content-area overflow-y-scroll">
        <div class="px-3">
          <checkbox-group
            :options="optionsMap"
            v-model="selected"
            :chooseOne="justOne"
          />
        </div>
      </div>
      <small v-else class="option text-secondary pl-2">
        {{ emptyMsg }}
      </small>

      <div v-if="!hideButtons" class="option pt-2 px-3">
        <div class="row">
          <div class="col-lg-6 px-1">
            <button
              class="btn w-100 btn-outline-secondary btn-sm d-flex justify-content-center align-items-center"
              @click="clear"
            >
              Limpar
            </button>
          </div>
          <div class="col-lg-6 px-1">
            <button
              class="btn w-100 btn-primary btn-sm d-flex justify-content-center align-items-center"
              @click="submit"
            >
              Aplicar
            </button>
          </div>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
const ELLIPSIS_VERY_SHORT = 15;
const ELLIPSIS_SHORT = 24;
const ELLIPSIS_LARGE = 60;
export default {
  props: {
    options: {
      type: Array,
      default: () => ["option 01"]
    },
    propName: {
      type: [Array, String],
      default: null
    },
    label: {
      type: [Array, String],
      default: ""
    },
    labelColor: {
      type: String,
      default: ""
    },
    hideButtons: {
      type: Boolean,
      default: false
    },
    fullObject: {
      type: Boolean,
      default: false
    },
    shortPlaceholder: {
      type: Boolean,
      default: true
    },
    veryShortEllipsis: {
      type: Boolean,
      default: true
    },
    emitOnSelect: {
      type: Boolean,
      default: false
    },
    emptyMsg: {
      type: [String, Array],
      default: "Sem opções disponíveis"
    },
    value: {
      type: Array,
      default: () => []
    },
    selectOne: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    selected(v) {
      if (this.emitOnSelect) this.$emit("input", v);
    },
    options(newV, oldV) {
      this.updateNameMap();
      this.clear();
    },
    value(v) {
      if (v?.join() !== this.selected?.join()) this.selected = v;
      this.selected = v;
    }
  },
  data() {
    return {
      window: {},
      selected: this.value,
      id_name_map: {}
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.updateNameMap();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    optionsMap() {
      return this.options.map(({ id, name }) => ({
        text: name,
        value: id,
        id
      }));
    },
    ellipsis() {
      if (this.veryShortEllipsis || this.window.width <= 768)
        return ELLIPSIS_VERY_SHORT;

      if (this.shortPlaceholder) return ELLIPSIS_SHORT;

      return ELLIPSIS_LARGE;
    },
    selectedItems() {
      return this.selected.map(s => {
        return this.getSelectedById(s);
      });
    },
    selected_value() {
      if (this.selected.length == 0) return "Selecione...";

      if (this.selected.length == 1) return this.id_name_map[this.selected[0]];

      if (this.selected.length == this.options.length) return "Todas";

      if (this.selected.length > 1) {
        return `${this.selected
          .map(o => this.id_name_map[o])
          .join(", ")
          .slice(0, this.ellipsis)}...`;
      }

      return `${this.id_name_map[this.selected[0]].slice(0, this.ellipsis)}...`;
    },
    justOne() {
      if (this.selectOne && this.selected.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    isDifferent(first, second) {
      if (Array.isArray(first) && Array.isArray(second)) {
        return JSON.stringify(first.sort()) !== JSON.stringify(second.sort());
      }

      return JSON.stringify(first) == JSON.stringify(second);
    },
    updateNameMap() {
      const new_id_name_map = {};
      for (let i in this.options) {
        new_id_name_map[this.options[i].id] = this.options[i].name;
      }
      this.id_name_map = new_id_name_map;
    },
    getSelectedById(id) {
      return this.options.find(selected => selected.id == id);
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    clear() {
      this.selected = [];
      this.$emit("input", []);
    },
    submit() {
      this.$emit("submit", this.fullObject ? this.selectedItems : this.value);
      this.$refs.dropdown.hide(true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.components-select-dropdown {
  .content-area {
    max-height: 300px;
    width: 100% !important;
  }

  label {
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #595959;
  }

  ::v-deep .dropdown-menu.show {
    height: fit-content;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  ::v-deep .dropdown-item-button {
    padding: 10px 16px;
    color: #000;
    margin-right: 30px;
    i {
      font-size: 24px;
      margin-right: 6px;
    }
  }

  ::v-deep .btn-light {
    background: #f5f5f5;
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    width: 283px;
    @media screen and (max-width: 991px) {
      width: 350px;
    }
    color: #000;
    min-height: 50px !important;
    max-height: 50px !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &::after {
      position: absolute;
      right: 5%;
      top: 50%;
      transform: rotate(-180deg);
    }
  }

  .btn-light:active {
    border: 1px solid #2e3c8c;
  }

  ::v-deep .dropdown-item:hover {
    background: transparent;
    color: #202124;
  }

  ::v-deep .dropdown-menu {
    background: #f5f5f5;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    outline: none;
    color: #000;
    width: 100% !important;
  }

  ::v-deep .form-check-label {
    letter-spacing: 0.015em;
    padding: -4px 5px;
    font-weight: bold;
    line-height: 150%;
    font-size: 14px;
    color: #202124;
  }

  @media screen and (max-width: 1400px) {
    small {
      font-size: 12px;
    }
  }

  small {
    word-break: break-all;
  }
}
</style>
