<template>
  <div
    class="components-pagination d-flex align-items-center justify-content-center"
  >
    <button
      :disabled="!pagination.previous"
      class="btn"
      @click="goToPage(value - 1)"
    >
      <i class="icon-arrow-left"></i>
    </button>

    <template v-if="!pages.includes(1)">
      <button class="btn" @click="goToPage(1)">
        {{ 1 }}
      </button>
      <div>...</div>
    </template>

    <button
      class="btn"
      v-for="page in pages"
      :key="page"
      @click="goToPage(page)"
      :class="{ 'btn-light text-dark': value == page }"
    >
      {{ page }}
    </button>

    <template v-if="!pages.includes(totalPages)">
      <div>...</div>
      <button class="btn" @click="goToPage(totalPages)">
        {{ totalPages }}
      </button>
    </template>

    <button
      :disabled="!pagination.next"
      class="btn"
      @click="goToPage(value + 1)"
    >
      <i class="icon-arrow-right"></i>
    </button>
  </div>
</template>

<script>
let timeout = null;

export default {
  props: {
    pagination: {
      type: Object,
      required: false,
      default: () => {}
    },
    value: {
      type: [Number, String],
      default: 1
    }
  },
  // watch: {
  //   value(val) {
  //     clearTimeout(timeout);
  //     timeout = setTimeout(() => {
  //       this.$emit("input", this.val);
  //     }, 300);
  //   }
  // },
  methods: {
    goToPage(page) {
      this.$emit("input", page);
      window.scrollTo(0, 0);
    }
  },
  computed: {
    totalPages() {
      const totalPages = Math.ceil(
        this.pagination.count / this.pagination.per_page
      );
      return isNaN(totalPages) ? 1 : totalPages;
    },
    pages() {
      const pages = [];
      for (let page = this.value - 2; page <= this.value + 2; page++) {
        if (page > 0 && page <= this.totalPages) {
          pages.push(page);
        }
      }
      return pages;
    }
  }
};
</script>

<style lang="scss">
.components-pagination {
  padding-top: 32px;
  input {
    height: 40px;
    width: 40px;
    border: 1px solid #fff;
    background: #fff;
    border-radius: 4px;
    text-align: center;
    color: #000;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.015em;
  }
  .paginator {
    height: 40px;
    width: 40px;
    border: 1px solid #fff;
    background: #fff;
    border-radius: 4px;
    text-align: center;
    h6 {
      text-align: center;
      color: #000;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.015em;
    }
  }

  button {
    i {
      color: #ffcc00;
      font-size: 28px;
    }
  }

  button:disabled {
    i {
      color: #737373 !important;
    }
  }
}
</style>
