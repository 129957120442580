import store from "../store";
import { dayDiff } from "../functions/helpers";

export default {
  install(Vue) {
    Vue.prototype.$user = new Vue({
      store,
      computed: {
        placeholderUrl() {
          return require("@/assets/images/placeholder/profile-placeholder.png");
        },
        insights() {
          return this.$store.state.user.insights;
        },
        profile() {
          return this.$store.state.user.profile;
        },
        user() {
          return this.$store.state.user.user;
        },
        service() {
          return this.$store.state.user.user.service;
        },
        isInactive() {
          return (
            this.user && (!this.service.is_active || this.daysToExpire < 0)
          );
        },
        daysToExpire() {
          if (!this.user || !this.service) return "";
          return dayDiff(this.service.expired_at);
        }
      }
    });
  }
};
