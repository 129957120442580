import { prepareParams } from "@/functions/helpers";
import constants from "@/constants.js";
const RESULTS_PER_PAGE = constants.JSON.RESULTS_PER_PAGE;

export default {
  getProvidersHome(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/providers/?limit=8&order_by=random")
        .then(response => {
          context.commit("setProviders", response.data.results);
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getProviders(context, data) {
    const search_terms = new URLSearchParams(prepareParams(data));
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/providers/?${search_terms}`)
        .then(({ data: { count, next, previous, total, results } }) => {
          let resultsApi = results;
          context.commit("setPagination", {
            count,
            next,
            previous,
            total,
            per_page: RESULTS_PER_PAGE
          });
          context.commit("setProviders", resultsApi);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getProvider(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/providers/${id}`)
        .then(response => {
          context.commit("setProvider", response.data);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCategories(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/providers/tags-products")
        .then(response => {
          context.commit("setCategories", response.data.results);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
