import axios from "axios";

window.axios = axios;

// window.axios.defaults.baseURL = "https://bora-conecta.herokuapp.com/v1";
window.axios.defaults.baseURL = "https://api.boraconecta.com.br/v1";

window.axios.defaults.timeout = 1000 * 60 * 5;

const TOKEN = "@_BORA_CONECTA_API_TOKEN_@";

const setAxiosHeader = token => {
  if (token) {
    window.axios.defaults.headers.common.Authorization = `JWT ${token}`;
  } else {
    delete window.axios.defaults.headers.common.Authorization;
  }
};

const setTokenInfo = tokenInfo =>
  localStorage.setItem("@_BORA_CONECTA_API_TOKEN_INFO_@", tokenInfo);

const setToken = token => {
  localStorage.setItem(TOKEN, token);
  setAxiosHeader(token);
};

const removeToken = () => {
  localStorage.removeItem(TOKEN);
  setAxiosHeader(null);
};

const getToken = () => {
  return localStorage.getItem(TOKEN);
};

const initAPI = () => {
  const token = getToken();
  if (token) {
    setAxiosHeader(token);
  }
};

export { setToken, setTokenInfo, getToken, removeToken, initAPI };
