import Vue from "vue";

import ActionDispatcher from "./ActionDispatcher.vue";
import ActionsDropdown from "./ActionsDropdown.vue";
import ResponsiveModal from "./ResponsiveModal.vue";
import SelectDropDown from "./SelectDropDown.vue";
import CheckboxGroup from "./CheckboxGroup.vue";
import Confirmation from "./Confirmation.vue";
import HollowButton from "./HollowButton.vue";
import CustomHeader from "./CustomHeader.vue";
import CustomFooter from "./CustomFooter.vue";
import SearchInput from "./SearchInput.vue";
import ImageInput from "./ImageInput.vue";
import Paginator from "./Paginator.vue";
import FileInput from "./FileInput.vue";
import FormGroup from "./FormGroup.vue";
import Snackbar from "./Snackbar.vue";
import Loading from "./Loading.vue";
import Warning from "./Warning.vue";
import Card from "./Card.vue";
import AutoCompleteInput from "./AutoCompleteInput.vue";
import CustomCheckbox from "./CustomCheckbox.vue";

Vue.component("action-dispatcher", ActionDispatcher);
Vue.component("actions-dropdown", ActionsDropdown);
Vue.component("select-dropdown", SelectDropDown);
Vue.component("responsive-modal", ResponsiveModal);
Vue.component("hollow-button", HollowButton);
Vue.component("checkbox-group", CheckboxGroup);
Vue.component("custom-header", CustomHeader);
Vue.component("custom-footer", CustomFooter);
Vue.component("confirmation", Confirmation);
Vue.component("search-input", SearchInput);
Vue.component("image-input", ImageInput);
Vue.component("file-input", FileInput);
Vue.component("form-group", FormGroup);
Vue.component("paginator", Paginator);
Vue.component("snackbar", Snackbar);
Vue.component("loading", Loading);
Vue.component("warning", Warning);
Vue.component("card", Card);
Vue.component("auto-complete-input", AutoCompleteInput);
Vue.component("custom-checkbox", CustomCheckbox);
