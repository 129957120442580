import { normalizeText, prepareParams } from "@/functions/helpers";
import constants from "@/constants.js";
const RESULTS_PER_PAGE = constants.JSON.RESULTS_PER_PAGE;

export default {
  get(context, params) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(
          `/projects/search?without_me=true&${prepareParams({
            ...params,
            status: "posted"
          })}`
        )
        .then(response => {
          const { count, next, previous, total, results } = response.data;
          context.commit("setOpportunities", results);
          context.commit("setPagination", {
            count,
            next,
            previous,
            total,
            per_page: RESULTS_PER_PAGE
          });
          resolve(results);
        })
        .catch(reject);
    });
  },
  getMyByStatus(context, { categories }) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/projects?status=" + categories.join(","))
        .then(({ data: { count, next, previous, results } }) => {
          context.commit("setMyOpportunities", results);
          context.commit("setPagination", { count, next, previous });
          resolve(results);
        })
        .catch(reject);
    });
  },
  getMy(context, { id, params }) {
    return new Promise((resolve, reject) => {
      const query_params = new URLSearchParams(prepareParams(params, "status"));
      window.axios
        .get(`/projects/search?user_id=${id}&${query_params}`)
        .then(({ data: { count, next, previous, results, total } }) => {
          context.commit("setMyOpportunities", results);
          context.commit("setPagination", {
            count,
            next,
            previous,
            total,
            per_page: RESULTS_PER_PAGE
          });
          resolve(results);
        })
        .catch(reject);
    });
  },
  create(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/projects/project/create", data)
        .then(response => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getOne(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/projects/project/" + id)
        .then(response => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  update(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/projects/project/update`, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  sendProposal(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/projects/proposal/create", data)
        .then(response => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },

  getProjectProposals(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/projects/proposals?project_id=${id}`)
        .then(resolve)
        .catch(reject);
    });
  },

  updateProposal(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/projects/proposal/update`, data)
        // .put(`projects/proposal/update`, { id, ...data })
        .then(response => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },

  removeProjectProposal(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .delete(`/projects/proposals?project_id=${id}`)
        .then(resolve)
        .catch(reject);
    });
  }
};
