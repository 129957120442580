import constants from "@/constants.js";
const RESULTS_PER_PAGE = constants.JSON.RESULTS_PER_PAGE;

export function toHumanDate(date) {
  return date
    .split("-")
    .reverse()
    .join("/");
}

export const getCurrencySymbol = currency => {
  let c = currency.toLowerCase();
  switch (c) {
    case "brl":
      return "R$";
    case "usd":
      return "US$";
    default:
      return "$";
  }
};

export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = ",",
  thousands = "."
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {}
};

export function dayDiff(dateString) {
  var today = new Date();
  var date = new Date(dateString);

  // To calculate the time difference of two dates
  var differenceInTime = date.getTime() - today.getTime();

  // To calculate the no. of days between two dates
  return Math.floor(differenceInTime / (1000 * 3600 * 24));
}

export const formFields = (fields, initial = {}) => {
  const form = {};
  const errors = {};
  for (const i in fields) {
    const field = fields[i];
    form[field] =
      initial &&
      initial[field] !== "" &&
      initial[field] !== undefined &&
      initial[field] !== null
        ? initial[field]
        : "";
    errors[field] = [];
  }
  return { form, errors, loading: false };
};

export const removeMask = maskedValue => {
  if (maskedValue) {
    return maskedValue
      .replace(/\s/g, "")
      .replace(/-/g, "")
      .replace(/\(/g, "")
      .replace(/\)/g, "")
      .replace(/\D/g, "")
      .trim();
  }
  return "";
};

export const getArrayOfDaysInMonth = (year, month) => {
  var numDaysInMonth, daysInWeek, daysIndex, index, day, daysArray;

  numDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  daysInWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
  daysIndex = { Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6 };
  let yearPrepared = month === 1 ? year - 1 : year;

  index =
    daysIndex[new Date(yearPrepared, month - 1, 1).toString().split(" ")[0]];

  daysArray = [];

  for (day = 1; day <= numDaysInMonth[month - 1]; day++) {
    if (day === 1) {
      const end = daysInWeek.findIndex(d => d === daysInWeek[index]);

      for (let weekIndex = end - 1; weekIndex >= 0; weekIndex--) {
        const beforeMonthDaysCount =
          month === 1 ? numDaysInMonth[11] : numDaysInMonth[month - 2];
        daysArray.push({
          day: beforeMonthDaysCount - weekIndex,
          isCurrentMonth: false,
          monthPosition: "before"
        });
      }

      daysArray.push({ day, isCurrentMonth: true });
    } else if (day === numDaysInMonth[month - 1]) {
      daysArray.push({ day, isCurrentMonth: true, monthPosition: "current" });

      const start = daysInWeek.findIndex(d => d === daysInWeek[index]);

      for (let weekIndex = 1; weekIndex <= 7 - start; weekIndex++) {
        daysArray.push({
          day: weekIndex,
          isCurrentMonth: false,
          monthPosition: "next"
        });
      }
    } else {
      daysArray.push({ day, isCurrentMonth: true });
    }

    index++;
    if (index == 7) index = 0;
  }

  return daysArray;
};

export const getYearRange = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const clearObject = object => {
  const clone_object = Object.assign({}, object);
  for (const i in clone_object) {
    if (
      clone_object[i] === "" ||
      clone_object[i] === undefined ||
      clone_object[i] === null
    ) {
      delete clone_object[i];
    }
  }
  return clone_object;
};

export const prepareParams = (params, field = null) => {
  const cleanedParams = clearObject(params);
  const cleaned = { ...cleanedParams };

  let payload = {};

  if (cleaned.page) {
    payload.page = cleaned.page;
    delete cleaned.page;
  }

  // This message param is for modal message located in Default.vue
  if (cleaned.message) {
    // payload.page = cleaned.message;
    delete cleaned.message;
  }

  if (cleaned.related_product) {
    payload.related_product = cleaned.related_product;
    delete cleaned.related_product;
  }
  payload.order = cleaned.order || "random";
  delete cleaned.order;

  if (cleaned.state) {
    payload.state = cleaned.state;
    delete cleaned.state;
  }
  if (cleaned.city) {
    payload.city = cleaned.city;
    delete cleaned.city;
  }

  if (field && params?.[field]) {
    payload[field] = params[field];
    delete cleaned[field];
  }

  payload = {
    ...payload,
    query: Object.entries(cleaned)
      .map(([_, v]) => v)
      .join(","),
    limit: constants.JSON.RESULTS_PER_PAGE,
    offset: constants.JSON.RESULTS_PER_PAGE * ((cleanedParams.page ?? 1) - 1)
  };

  return payload;
};

export function isValidCPF(cpf) {
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  cpf = cpf.split("");
  const validator = cpf
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map(el => +el);
  const toValidate = pop =>
    cpf
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map(el => +el);
  const rest = (count, pop) =>
    ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      11) %
    10;
  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1]);
}

export function isValidCNPJ(cnpj) {
  const format = value => value.replace(/[^\d]+/g, "");
  const isValidNumber = (value, count) =>
    format(value).length === count && !format(value).match(/(\d)\1{10}/);
  const sum = (array, start) =>
    array.reduce((total, el, i) => total + el * (start - i), 0);
  const rest = value => value % 11;

  const validator = value =>
    format(value)
      .split("")
      .splice(format(value).length - 2)
      .map(el => +el);

  const toValidate = (value, end, start = 0) =>
    format(value)
      .split("")
      .filter((digit, index, array) => index >= start && index <= end && digit)
      .map(el => +el);

  const validate = (firstDigit, lastDigit, validator) =>
    firstDigit === validator[0] && lastDigit === validator[1];

  if (!isValidNumber(cnpj, 14)) return false;

  const digit = sum => (rest(sum) < 2 ? 0 : 11 - rest(sum));

  const firstDigit = digit(
    sum(toValidate(cnpj, 3), 5) + sum(toValidate(cnpj, 11, 4), 9)
  );
  const lastDigit = digit(
    sum(toValidate(cnpj, 4), 6) + sum(toValidate(cnpj, 12, 5), 9)
  );

  return validate(firstDigit, lastDigit, validator(cnpj));
}

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const normalizeText = txt =>
  `${txt}`
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

export const formatPhoneNumber = phoneNumberString => {
  var x = phoneNumberString.replace(/\D/g, "").match(/(\d{2})(\d{5})(\d{4})/);
  return "(" + x[1] + ") " + x[2] + "-" + x[3];
};

export const convertHTMLToPlainText = html_text => {
  var tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = html_text;
  return tempDivElement.textContent || tempDivElement.innerText || "";
};

export function firstLetterUppercase(text) {
  text = text.toLowerCase();
  let tempArr = text.split(" ");
  for (let i = 0; i < tempArr.length; i++) {
    tempArr[i] = tempArr[i][0].toUpperCase() + tempArr[i].substr(1);
  }
  return tempArr.join(" ");
}
