export default {
  getServices(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/perfils/certificate-services?limit=9999")
        .then(response => {
          context.commit("setServicesTag", response.data.results);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getServicesUser(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/perfils/performed-services/${id}/user?limit=9999`)
        .then(response => {
          context.commit("setServices", response.data.results);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createService(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/perfils/performed-services/create", data)
        .then(response => {
          resolve(response);
        })
        .catch(reject);
    });
  },
  // getMyServices(context) {
  //   return new Promise((resolve, reject) => {
  //     window.axios
  //       .get("/perfils/perfomed-services")
  //       .then(response => {
  //         context.commit("setServices", response.data.results);
  //         resolve(response.data);
  //       })
  //       .catch(reject);
  //   });
  // },
  updateService(context, { id, data }) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/perfils/performed-services/${id}`, data)
        .then(response => {
          resolve(response);
        })
        .catch(reject);
    });
  },
  deleteService(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .delete(`/perfils/performed-services/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(reject);
    });
  }
};
