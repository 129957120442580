<template>
  <div class="actions-dropdown">
    <b-dropdown
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
      right
    >
      <template #button-content>
        <slot />
      </template>
      <b-dropdown-item
        v-for="(action, index) in actions"
        :key="index"
        @click="onClickItem(action, index)"
        class="d-flex align-items-start justify-content-start"
      >
        <small
          class="d-flex align-items-center justify-content-start w-100"
          :class="action.class"
        >
          <i :class="action.icon"></i>
          <strong v-html="action.text"></strong>
        </small>
        <confirmation
          v-if="action.confirmation.enable"
          iconType="warning"
          :title="
            action.confirmation.title
              ? action.confirmation.title
              : 'Tem certeza que deseja realizar esta ação?'
          "
          cancelButtonText="Cancelar"
          v-model="visible[index]"
          :confirmButtonClass="action.confirmation.submitButtonClass"
          :confirmButtonText="action.confirmation.submitText"
          :text="action.confirmation.text"
          @confirm="submitConfirm(action, index)"
          @cancel="visible[index] = false"
        />
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    actions: {
      type: Array,
      default: () => [
        {
          icon: "icon-pencil",
          text: "Editar",
          class: "text-dark",
          event: "edit"
        }
      ]
    }
  },
  data() {
    return {
      visible: {
        ...Array(this.actions.length).fill(false)
      }
    };
  },
  methods: {
    onClickItem(action, index) {
      if (action.confirmation.enable) {
        this.visible[index] = true;
        return;
      }

      this.$emit(action.event);
    },
    submitConfirm(action, index) {
      this.$emit(action.event);
      this.visible[index] = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.actions-dropdown {
  ::v-deep .dropdown-item {
    padding: 10px 16px;
    color: #fff;
    margin-right: 30px;
    i {
      font-size: 24px;
      margin-right: 6px;
    }
  }

  ::v-deep .dropdown-item:hover {
    background: #202124;
    color: #8999f3;
  }

  ::v-deep .dropdown-menu {
    background: #202124;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    outline: none;
    color: #fff;
  }
}
</style>
