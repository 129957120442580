export default {
  setOpportunities(state, opportunities) {
    state.opportunities = opportunities;
  },
  setMyOpportunities(state, my_opportunities) {
    state.my_opportunities = my_opportunities;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  }
};
