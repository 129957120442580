import { setToken, setTokenInfo, removeToken } from "../../setup/api";
import { removeUserInfo } from "../../setup/auth";
import { saveUserInfo } from "../../setup/auth";

export default {
  login(context, { email, password }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await window.axios.post("/accounts/signin", {
          email,
          password
        });
        await setToken(response.data.token);
        await saveUserInfo(response.data);
        await context.dispatch("getMyProfile", response.data.id);

        await context.commit("setUser", response.data);
        context.commit("setLogged", true);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  create(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/accounts/create", data)
        .then(function(response) {
          saveUserInfo(response.data);
          setToken(response.data.token);
          context.commit("setUser", response.data);
          context.commit("setLogged", true);
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  update(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put("/accounts/update", data)
        .then(function(response) {
          saveUserInfo(response.data);
          // setToken(response.data.token);
          context.commit("setUser", response.data);
          context.commit("setLogged", true);
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  changePassword(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put("/accounts/change-password", data) // data is { old_password, new_password }
        .then(() => {
          context.commit("reset");
          removeToken();
          removeUserInfo();
          resolve();
        })
        .catch(reject);
    });
  },
  logout(context, data) {
    return new Promise((resolve, reject) => {
      context.commit("reset");
      removeToken();
      removeUserInfo();
      resolve();
    });
  },
  forgotPassword(context, email) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/accounts/forgot`, { email })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  },
  resetPassword(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/accounts/reset-password`, data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(reject);
    });
  },
  get(context) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await window.axios.get("/accounts/");

        await context.dispatch("getMyProfile", response.data.id);

        context.commit("setUser", response.data);
        context.commit("setLogged", true);

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getUserInfos(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/accounts`)
        .then(function(response) {
          context.commit("updateUser", response.data);
          resolve();
        })
        .catch(reject);
    });
  },
  getUserProfileInfos(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/perfils/perfil/` + id)
        .then(function(response) {
          if (response.data?.id) {
            context.commit("updateProfile", response.data);
          }
          resolve(response.data);
        })
        .catch(er => {
          reject(er);
        });
    });
  },
  getMyProfile(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/perfils/perfil`)
        .then(response => {
          if (response.data?.id) {
            context.commit("updateProfile", response.data);
          }
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getInsights(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/statistics`)
        .then(function(response) {
          context.commit("setInsights", response.data);
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getProfile(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/perfils/perfil/` + id)
        .then(function(response) {
          if (response.data?.id) context.commit("updateProfile", response.data);
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getThirdProfile(context, slug) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/perfils/perfil/` + slug)
        .then(function(response) {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  createProfile(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/perfils/perfil/create", data)
        .then(response => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  updateProfile(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put("/perfils/perfil/update", data)
        .then(response => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  updateService(context, { data, id }) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/perfils/service/update/${id}`, data)
        .then(resolve)
        .catch(reject);
    });
  },
  deleteService(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .delete(`/perfils/service/delete/${id}`)
        .then(resolve)
        .catch(reject);
    });
  },
  getService(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/perfils/service/${id}`)
        .then(resolve)
        .catch(reject);
    });
  },
  sendRating(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/perfils/rating/create`, data)
        .then(function(response) {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getPerfilRatings(context, { profile, offset }) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/perfils/ratings?perfil_id=${profile}&limit=5&offset=${offset}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  createService(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/perfils/service/create", data)
        .then(resolve)
        .catch(reject);
    });
  },
  getPerfilServices(context, profileId) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/perfils/services?perfil_id=${profileId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(reject);
    });
  }
};
