<template>
  <div class="certificate-modal-component ">
    <div
      class="custom-header d-flex justify-content-center align-items-center mb-4"
    >
      <img
        v-if="type == 'certificate'"
        src="@/assets/images/home/certificate.png"
        alt="Certificado"
      />
      <img v-else src="@/assets/images/home/login.svg" alt="Login" />
    </div>
    <div class="custom-body text-center">
      <h5 class="mb-0">{{ text1 }}</h5>
      <h2 class="mb-0">{{ title }}</h2>
      <h5>{{ text2 }}</h5>
      <div class="row justify-content-center my-4">
        <div class="col-lg-6">
          <a
            v-if="type == 'certificate'"
            target="_blank"
            :href="buttonLink"
            class="btn btn-primary"
            >{{ buttonCopy }}</a
          >
          <router-link
            class="btn btn-primary btn-block"
            v-else
            :to="{ name: buttonLink }"
            >{{ buttonCopy }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    text1: {
      type: String,
      required: true
    },
    text2: {
      type: String,
      required: true
    },
    buttonCopy: {
      type: String,
      required: true
    },
    buttonLink: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  }
};
</script>
<style lang="scss" scoped>
.certificate-modal-component {
  background: #fff;
  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #595959;
  }
  h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #595959;
  }

  .btn-primary {
    font-weight: 700;
  }
}
</style>
