<template>
  <div class="components-custom-footer w-100 pb-4">
    <div class="container-fluid">
      <div class="d-none d-lg-inline text-left mb-4 mb-lg-0 mr-lg-4 logo">
        <img
          width="115"
          height="61.8"
          src="@/assets/images/logo.png"
          alt="Logo Elite Digital"
        />
      </div>
      <div
        class="d-flex flex-column align-items-start justify-content-center flex-fill"
      >
        <router-link :to="{ name: 'home' }" class="menu-option special-mb">
          Visão geral
        </router-link>
        <router-link
          :to="{ name: 'professionals' }"
          class="menu-option special-mb"
        >
          Ver profissionais
        </router-link>
        <button @click="checkOpportunity" class="menu-option mb-3 mb-lg-0">
          Oportunidades
        </button>
        <button @click="checkProjects" class="menu-option">
          Minhas oportunidades
        </button>
      </div>
      <div
        class="d-flex flex-column align-items-start align-items-lg-end pr-lg-3"
      >
        <div
          class="d-flex align-items-center justify-content-start justify-content-lg-between"
        >
          <a
            class="social-media-link mr-2"
            target="_blank"
            href="https://www.tiktok.com/@boranaobra?is_from_webapp=1&sender_device=pc"
          >
            <i class="icon-tiktok"></i>
          </a>
          <a
            class="social-media-link mr-2"
            target="_blank"
            href="https://www.facebook.com/BORAnaOBRA"
          >
            <i class="icon-facebook"></i>
          </a>
          <a
            class="social-media-link mr-2"
            target="_blank"
            href="https://www.instagram.com/boranaobra/"
          >
            <i class="icon-instagram"></i>
          </a>
          <a
            class="social-media-link"
            target="_blank"
            href="https://www.youtube.com/c/BORAnaOBRA"
          >
            <i class="icon-youtube"></i>
          </a>
        </div>
        <div class="hr mb-4" />
        <a
          target="_blank"
          href="/politicas-de-privacidade"
          class="text-decoration-none text-light mb-2"
          style="font-size: 12px;"
        >
          Política de privacidade
        </a>
        <a
          target="_blank"
          href="/termos-de-uso"
          class="text-decoration-none text-light"
          style="font-size: 12px;"
        >
          Termos e condições
        </a>
      </div>
    </div>
    <responsive-modal v-model="showLogin" :hideHeader="false" centered>
      <certification-modal
        title="TER ACESSO A TODOS OS BENEFÍCIOS"
        text1="Faça login ou cadastre-se no Bora Conecta para"
        text2="da plataforma."
        buttonCopy="Entrar"
        buttonLink="login"
        type="login"
      />
    </responsive-modal>
    <responsive-modal v-model="showCertification" :hideHeader="false" centered>
      <certification-modal
        title="Formação em Projetos e Obras BORAnaOBRA"
        text1="Somente certificados na"
        text2="podem ver oportunidades."
        buttonCopy="Quero ser certificado"
        buttonLink="https://google.com"
        type="certificate"
      />
    </responsive-modal>
  </div>
</template>

<script>
import CertificationModal from "@/components/home/CertificationModal";
export default {
  components: { CertificationModal },
  data() {
    return {
      showLogin: false,
      showCertification: false
    };
  },
  computed: {
    isCertified() {
      return (
        this.$user.profile &&
        this.$user.profile.emblem &&
        this.$user.profile.emblem.is_active
      );
    }
  },
  methods: {
    checkProjects() {
      if (this.$user.user) {
        this.doEmit();
        this.$router.push({ name: "new-opportunity" });
      } else {
        this.showLogin = true;
      }
    },
    checkOpportunity() {
      if (this.$user.user) {
        if (this.$user.profile.emblem) {
          this.doEmit();
          this.$router.push({ name: "opportunities" });
        } else {
          this.showCertification = true;
        }
      } else {
        this.showLogin = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.components-custom-footer {
  width: 100%;
  background: #000;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);

  .container-fluid {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 375px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .logo {
    padding-right: 20px;
  }

  .menu-option {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.015em;
    color: #fbfbfb;
    line-height: 25px;
    background: transparent;
    padding: 0;
    border: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .special-mb {
    @media screen and (max-width: 576px) {
      margin-bottom: 1rem !important;
    }
  }

  .link-control {
    @media screen and (max-width: 768px) {
      flex: 1 1 auto;
    }
  }

  .social-media-link {
    color: #fff;
    font-size: 28px;
    text-decoration: none;
  }

  ::v-deep .hr {
    background-color: $primary;
    height: 2px;
    width: 130px;
  }
}
</style>
