<template>
  <div class="components-checkbox-group">
    <div
      class="checkbox d-flex align-items-start"
      :class="{ blocked: item.disabled, 'choose-one': choosedOne }"
      v-for="(item, index) in options"
      :key="index"
      @click="toggle(index)"
    >
      <i
        :class="{
          'icon-square text-secondary': !innerSelected.find(e => e == item.id),
          'icon-checked-square text-dark bg-primary': innerSelected.find(
            e => e == item.id
          )
        }"
      ></i>
      <span class="small font-weight-bold mt-n1">{{ item.text }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => {
        [];
      }
    },
    value: {
      type: Array,
      default: () => []
    },
    chooseOne: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerSelected: [...this.value]
    };
  },
  watch: {
    innerSelected(v) {
      this.$emit("input", v);
    },
    value(v) {
      if (v.join() != this.innerSelected.join()) this.innerSelected = v;
    }
  },
  methods: {
    toggle(i) {
      const el = this.options[i];
      if (this.innerSelected.find(e => e == el.id) >= 0) {
        this.innerSelected = this.innerSelected.filter(
          i => Number(i) != Number(el.id)
        );
      } else {
        this.innerSelected = [...this.innerSelected, Number(el.id)];
      }
    }
  },
  computed: {
    choosedOne() {
      if (this.chooseOne && this.value.length) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.components-checkbox-group {
  padding-left: 5px;
  .checkbox {
    cursor: pointer;
    padding-top: 8px;
    i {
      font-size: 16px;
      margin-right: 8px;
      border-radius: 50%;
    }

    span {
      color: #595463;
    }
  }

  .blocked {
    opacity: 0.5;
  }
  .choose-one {
    pointer-events: none;
  }
}
</style>
