import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import BootstrapVue from "bootstrap-vue";
import VueTheMask from "vue-the-mask";
import VueTelInput from "vue-tel-input";
import VCalendar from "v-calendar";
import VTooltip from "v-tooltip";
import VueCarousel from "vue-carousel";

import "./assets/icomoon/style.css";
import "./assets/scss/app.scss";
import "moment/locale/pt-br";
import "./components";
import "./plugins";
import { initAPI } from "./setup/api";
import { initUserInfo } from "./setup/auth";

// import Chart from "chart.js";

// Chart.defaults.global.defaultFontFamily = "'Montserrat', sans-serif";
// Chart.defaults.global.defaultFontStyle = "bold";

Vue.use(BootstrapVue, {
  formRadioCheckGroups: {
    buttonVariant: "primary",
    variant: "primary"
  },
  formRadioCheckControls: {
    buttonVariant: "primary",
    variant: "primary"
  }
});
Vue.use(VueTelInput);
Vue.use(VueTheMask);
Vue.use(VCalendar);
Vue.use(VTooltip);
Vue.use(VueCarousel);

initUserInfo();
initAPI();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
