export default {
  setUser(state, user) {
    if (state.user) {
      for (let i in user) {
        state.user[i] = user[i];
      }
    } else {
      state.user = user;
    }
  },
  setServices(state, services) {
    state.services = services;
  },
  updateUser(state, data) {
    for (const i in data) {
      state.user[i] = data[i];
    }
  },
  setLogged(state, logged) {
    state.logged = logged;
  },
  reset(state) {
    state.user = null;
    state.logged = false;
    state.profile = {};
  },
  setInsights(state, insights) {
    state.insights = insights;
  },
  setProfile(state, profile) {
    state.profile = profile;
  },
  updateProfile(state, profile) {
    for (const i in profile) state.profile[i] = profile[i];
  }
};
