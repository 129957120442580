<template>
  <div class="components-image-input form-group">
    <button type="button" class="btn btn-sm" @click="openImgSelectBox">
      <slot v-if="isCover || !innerValue || innerValue === ''" />
      <div v-if="innerValue && !noPreview && !isCover" class="image-preview">
        <img :src="innerValue" />
        <div class="icon-wrapper">
          <i class="icon-image"></i>
        </div>
      </div>
      <div class="row position-relative">
        <div class="col">
          <input
            :id="id"
            type="file"
            accept="image/*"
            @change="inputImgFileChanged"
          />
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import { toBase64 } from "@/functions/helpers";

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    noPreview: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    isCover: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      innerValue: this.value
    };
  },
  model: {
    prop: "value",
    type: "change"
  },
  watch: {
    innerValue(newVal) {
      if (newVal != this.value) {
        this.$emit("input", newVal);
      }
    },
    value(newVal) {
      if (this.innerValue !== newVal) {
        this.innerValue = newVal;
      }
    }
  },
  methods: {
    openImgSelectBox() {
      document.getElementById(this.id).click();
    },
    async inputImgFileChanged(event) {
      if (event.target.files && event.target.files.length) {
        const arrFiles = Array.from(event.target.files);
        const files = arrFiles.map((file, index) => {
          const src = window.URL.createObjectURL(file);
          return { file, src };
        });

        try {
          const base64Img = await toBase64(files[0].file);
          this.$emit("input", `${base64Img}`);
        } catch (error) {
          this.$message(error);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.components-image-input {
  .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    background-color: #ffcc00;
    border: 1px solid #f5f5f5;
  }

  .image-preview {
    position: relative;
    img {
      height: 120px;
      width: 120px;
      border-radius: 50%;
      object-fit: cover;
      box-shadow: 0 0 16px rgba(100, 100, 100, 0.1);
    }

    .icon-wrapper {
      background: #ffcc00;
      border: 1px solid #f5f5f5;
      border-radius: 24px;
      width: 40px;
      height: 40px;
      z-index: 2;

      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: 100%;
      left: 110%;
      transform: translate(-100%, -110%);
      i {
        font-size: 26px;
        color: #fff;
      }
    }
  }

  input {
    position: absolute;
    visibility: hidden;
  }
}
</style>
